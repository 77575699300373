import {
  Container,
  Navbar,
  Row,
  Col,
  Card,
  Table,
  Button,
  Form,
} from "react-bootstrap";
import Navbars from "../components/Navbars";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

const Kendaraan = () => {
  const Navigate = useNavigate(null);
  useEffect(() => {
    if (Cookies.get("token")) {
      tokenValidate();
      getDataKendaraan();
    } else {
      Navigate("/login");
    }
  }, []);

  const tokenValidate = () => {
    axios
      .post(
        `https://9d89-180-241-162-153.ngrok-free.app/admin/token/verify?token=${Cookies.get(
          "token"
        )}`
      )
      .catch((err) => {
        Cookies.set("token", false);
        Navigate("/login");
      });
  };
  const [data, setData] = useState([]);
  const getDataKendaraan = () => {
    axios
      .post(
        `https://9d89-180-241-162-153.ngrok-free.app/admin/view/kendaraan?token=${Cookies.get(
          "token"
        )}`
      )
      .then((ress) => {
        setData(ress.data.data);
      })
      .catch((err) => {
        Cookies.set("token", false);
        Navigate("/login");
      });
  };
  const formNoPolisi = useRef(null);
  const formType = useRef(null);
  const addDataKendaraan = (e) => {
    e.preventDefault();
    if (formNoPolisi.current.value && formType.current.value) {
      axios
        .post(
          `https://9d89-180-241-162-153.ngrok-free.app/admin/add/kendaraan?token=${Cookies.get(
            "token"
          )}`,
          {
            noPolisi: formNoPolisi.current.value,
            type: formType.current.value,
          }
        )
        .then((ress) => {
          alert("berhasil menambahkan");
          window.location.reload();
        })
        .catch((err) => {
          Cookies.set("token", false);
          Navigate("/login");
        });
    } else {
      formNoPolisi.current.className += " border-danger";
      formType.current.className += " border-danger";
    }
  };
  return (
    <>
      <Navbars onwhat="kendaraan" />
      <section className="py-4">
        <Container>
          <Row className="g-3">
            <Col md="6">
              <Card className="rounded-3 shadow">
                <Card.Body className="p-4">
                  <div className="text-center">
                    <h5 className="fw-bold text-danger d-flex align-items-center gap-2 justify-content-center">
                      TAMBAHKAN KENDARAAN
                      <i class="bx bx-plus fs-3"></i>
                    </h5>
                  </div>
                  <Form className="mt-5" onSubmit={addDataKendaraan}>
                    <Form.Group className="mb-3">
                      <Form.Label>Nomor Polisi</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="BM 2103 AS"
                        className="focus-ring focus-ring-danger"
                        ref={formNoPolisi}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Type</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Vario 160"
                        className="focus-ring focus-ring-danger"
                        ref={formType}
                      />
                    </Form.Group>
                    <Button
                      className="w-100 bg-danger border-danger"
                      type="submit"
                    >
                      Tambahkan
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6">
              <Card className="rounded-3 shadow">
                <Card.Body className="p-4">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nomor Polisi</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => {
                        return (
                          <tr key={index + 1}>
                            <td>{index + 1}</td>
                            <td>{item.no_polisi}</td>
                            <td>{item.type}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Kendaraan;
