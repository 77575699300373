import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import Navbars from "../components/Navbars";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { FormatRupiah } from "@arismun/format-rupiah";

const SpareParts = () => {
  const Navigate = useNavigate(null);
  useEffect(() => {
    if (Cookies.get("token")) {
      tokenValidate();
      getDataParts();
    } else {
      Navigate("/login");
    }
  }, []);

  const tokenValidate = () => {
    axios
      .post(
        `https://9d89-180-241-162-153.ngrok-free.app/admin/token/verify?token=${Cookies.get(
          "token"
        )}`
      )
      .catch((err) => {
        Cookies.set("token", false);
        Navigate("/login");
      });
  };
  const [data, setData] = useState([]);
  const getDataParts = () => {
    axios
      .post(
        `https://9d89-180-241-162-153.ngrok-free.app/admin/view/parts?token=${Cookies.get(
          "token"
        )}`
      )
      .then((ress) => {
        setData(ress.data.data);
      })
      .catch((err) => {
        Cookies.set("token", false);
        Navigate("/login");
      });
  };
  const formNamaPart = useRef(null);
  const formHarga = useRef(null);
  const handleAddPart = (e) => {
    e.preventDefault();
    if (formHarga.current.value && formNamaPart.current.value) {
      axios
        .post(
          `https://9d89-180-241-162-153.ngrok-free.app/admin/add/parts?token=${Cookies.get(
            "token"
          )}`,
          {
            sparePart: formNamaPart.current.value,
            harga: formHarga.current.value,
          }
        )
        .then((ress) => {
          alert("berhasil menambahkan !");
          window.location.reload();
        })
        .catch((err) => {
          Cookies.set("token", false);
          Navigate("/login");
        });
    } else {
      formNamaPart.current.className += " border-danger";
      formHarga.current.className += " border-danger";
    }
  };
  return (
    <>
      <Navbars onwhat="spareParts" />
      <section className="py-4">
        <Container>
          <Row className="g-3">
            <Col md="6">
              <Card className="rounded-3 shadow">
                <Card.Body className="p-4">
                  <div className="text-center">
                    <h5 className="fw-bold text-danger d-flex align-items-center gap-2 justify-content-center">
                      TAMBAHKAN SPARE PART
                      <i class="bx bxs-cog fs-3"></i>
                    </h5>
                  </div>
                  <Form className="mt-5" onSubmit={handleAddPart}>
                    <Form.Group className="mb-3">
                      <Form.Label>Nama Spare Parts</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Karet CVT Vario 160"
                        className="focus-ring focus-ring-danger"
                        ref={formNamaPart}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Harga</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="50000"
                        className="focus-ring focus-ring-danger"
                        ref={formHarga}
                      />
                    </Form.Group>
                    <Button
                      className="w-100 bg-danger border-danger"
                      type="submit"
                    >
                      Tambahkan
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6">
              <Card className="rounded-3 shadow">
                <Card.Body className="p-4">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Spare Parts</th>
                        <th>Harga</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.spare_parts}</td>
                            <td>
                              <FormatRupiah value={item.harga} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SpareParts;
