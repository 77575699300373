import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Image,
  Button,
} from "react-bootstrap";
import logo from ".././assets/img/logo.jpg";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Navbars = (props) => {
  const Navigate = useNavigate(null);
  useEffect(() => {
    checkPage(props.onwhat);
  }, []);
  const menuBeranda = useRef(null);
  const menuSpareParts = useRef(null);
  const menuPelanggan = useRef(null);
  const menuKendaraan = useRef(null);
  const menuRiwayatTransaksi = useRef(null);
  const checkPage = (p) => {
    if (p === "beranda") {
      menuBeranda.current.className += " text-dark";
    } else if (p === "spareParts") {
      menuSpareParts.current.className += " text-dark";
    } else if (p === "pelanggan") {
      menuPelanggan.current.className += " text-dark";
    } else if (p === "kendaraan") {
      menuKendaraan.current.className += " text-dark";
    } else if (p === "riwayatTransaksi") {
      menuRiwayatTransaksi.current.className += " text-dark";
    }
  };

  const handleLogout = () => {
    Cookies.set("token", undefined);
    Navigate("/login");
  };
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary shadow p-3">
        <Container>
          <Navbar.Brand href="#home" className="d-flex align-items-end">
            <Image className="logo-honda" src={logo} />
            <h5 className="fw-bold text-danger">AHASS 904</h5>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto align-items-center">
              <Nav.Link ref={menuBeranda} onClick={() => Navigate("/")}>
                Beranda
              </Nav.Link>
              <Nav.Link
                ref={menuSpareParts}
                onClick={() => Navigate("/spare-parts")}
              >
                Spare Parts
              </Nav.Link>
              <Nav.Link
                ref={menuPelanggan}
                onClick={() => Navigate("/pelanggan")}
              >
                Pelanggan
              </Nav.Link>
              <Nav.Link
                ref={menuKendaraan}
                onClick={() => Navigate("/kendaraan")}
              >
                Kendaraan
              </Nav.Link>
              <Nav.Link
                ref={menuRiwayatTransaksi}
                onClick={() => Navigate("/riwayat-trasnsaksi")}
              >
                Riwayat Transaksi
              </Nav.Link>
              <Nav.Link>
                <Button
                  className="bg-danger border-danger d-flex align-items-center gap-2"
                  onClick={handleLogout}
                >
                  Logout<i class="bx bx-log-out mt-1"></i>
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default Navbars;
