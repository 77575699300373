import { Container, Form, Button, Card, Alert } from "react-bootstrap";
import Cookies from "js-cookie";
import { Component, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Logins = () => {
  const Navigate = useNavigate(null);
  const elemetAlert = useRef(null);
  useEffect(() => {
    if (Cookies.get("token")?.length > 30) {
      Navigate("/");
    } else if (Cookies.get("token")) {
      elemetAlert.current.className = "fade alert alert-danger show";
      elemetAlert.current.innerHTML = "session berakhir";
    } else {
      Navigate("/login");
    }
  }, []);

  const formUsername = useRef(null);
  const formPassword = useRef(null);
  const handleLogin = (e) => {
    e.preventDefault();
    if (formUsername.current.value && formPassword.current.value) {
      axios
        .post(`https://9d89-180-241-162-153.ngrok-free.app/admin/login`, {
          username: formUsername.current.value,
          password: formPassword.current.value,
        })
        .then((ress) => {
          Cookies.set("token", ress.data.data.token);
          elemetAlert.current.className = "fade alert alert-success show";
          elemetAlert.current.innerHTML = "Login Berhasil";
          setTimeout(() => {
            Navigate("/");
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          elemetAlert.current.className = "fade alert alert-danger show";
          elemetAlert.current.innerHTML = "username atau password salah";
        });
    } else {
      elemetAlert.current.className = "fade alert alert-warning show";
      elemetAlert.current.innerHTML = "Masukkan username dan password";
      formUsername.current.className += " border-danger";
      formPassword.current.className += " border-danger";
    }
  };
  return (
    <>
      <section className="py-5">
        <Container className="d-flex justify-content-center flex-column align-items-center">
          <h2 className="mb-5 text-danger fw-bold">AHASS 904</h2>
          <Card className="w-50 p-5 shadow rounded-5">
            <Card.Body>
              <Form onSubmit={handleLogin}>
                <Alert variant="danger" className="d-none" ref={elemetAlert}>
                  This is a alert—check it out!
                </Alert>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter email"
                    className="p-3"
                    ref={formUsername}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    className="p-3"
                    ref={formPassword}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 border-danger bg-danger mt-3 p-3"
                >
                  Login
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
};
export default Logins;
