import { Card, Container, Image, Nav, Table } from "react-bootstrap";
import logo from "./../assets/img/logo.jpg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { FormatRupiah } from "@arismun/format-rupiah";

const Invoice = () => {
  const Navigate = useNavigate(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    if (Cookies.get("token")) {
      tokenValidate();
      if (!localStorage.getItem("click")) {
        Navigate("/riwayat-trasnsaksi");
      } else {
        axios
          .post(
            `https://9d89-180-241-162-153.ngrok-free.app/admin/invoice/print/${localStorage.getItem(
              "click"
            )}?token=${Cookies.get("token")}`
          )
          .then((ress) => {
            setData(ress.data.data);
          })
          .catch((err) => {
            Cookies.set("token", false);
            Navigate("/login");
          });
      }
    } else {
      Navigate("/login");
    }
  }, []);

  const tokenValidate = () => {
    axios
      .post(
        `https://9d89-180-241-162-153.ngrok-free.app/admin/token/verify?token=${Cookies.get(
          "token"
        )}`
      )
      .catch((err) => {
        Cookies.set("token", false);
        Navigate("/login");
      });
  };

  const handlePrint = () => {
    window.print();
  };
  return (
    <>
      <section className="py-4">
        <Container>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="text-danger fw-bold">AHASS 904</h3>
            <Image src={logo} className="logo-honda" />
          </div>
          <div className="d-flex justify-content-between mb-3">
            <div>
              <div>
                <span>No : </span>
                <span>{data?.no_faktur} </span>
              </div>
              <div>
                <span>Nama : </span>
                <span>{data?.pelanggan?.nama_pelanggan} </span>
              </div>
              <div>
                <span>Alamat : </span>
                <span>{data?.alamat} </span>
              </div>
            </div>
            <div>
              <div>
                <span>Type : </span>
                <span>{data?.kendaraan[0]?.type} </span>
              </div>
              <div>
                <span>No Polisi : </span>
                <span>{data?.kendaraan[0]?.no_polisi} </span>
              </div>
              <div>
                <span>Tanggal : </span>
                <span>{data?.tanggal} </span>
              </div>
            </div>
          </div>
          <Card className="p-0 border-0">
            <Card.Body className="p-0">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Spare Parts</th>
                    <th>Harga Satuan</th>
                    <th>Jumlah</th>
                    <th>Harga Total</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.produk?.map((item, index) => {
                    return (
                      <tr key={index + 1}>
                        <td>{index + 1}</td>
                        <td>{item.spare_part}</td>
                        <td>
                          <FormatRupiah value={item.harga_satuan} />
                        </td>
                        <td>{item.jumlah}</td>
                        <td>
                          <FormatRupiah value={item.harga_total} />
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td colSpan={4}>Ongkos</td>
                    <td>
                      <FormatRupiah value={data?.pay?.ongkos} />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="fw-bold">
                      Total
                    </td>
                    <td className="bg-danger bg-opacity-50">
                      <FormatRupiah value={data?.pay?.total_pembayaran} />
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="d-flex justify-content-end">
                <a href="#" onClick={handlePrint}>
                  Print Faktur
                </a>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
};
export default Invoice;
