import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "boxicons";
import Dashboard from "./pages/Dashboard";
import SpareParts from "./pages/SpareParts";
import Pelanggan from "./pages/Pelanggan";
import Kendaraan from "./pages/kendaraan";
import RiwayatTransaksi from "./pages/RiwayatTransaksi";
import Invoice from "./pages/Invoice";
import Logins from "./pages/Logins";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/spare-parts" element={<SpareParts />} />
          <Route path="/pelanggan" element={<Pelanggan />} />
          <Route path="/kendaraan" element={<Kendaraan />} />
          <Route path="/riwayat-trasnsaksi" element={<RiwayatTransaksi />} />
          <Route path="/invoice/print" element={<Invoice />} />
          <Route path="/login" element={<Logins />} />
          <Route path="*" element={<Logins />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
