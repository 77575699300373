import {
  Card,
  Col,
  Container,
  Navbar,
  Row,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import Navbars from "../components/Navbars";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { FormatRupiah } from "@arismun/format-rupiah";

const Dashboard = () => {
  const Navigate = useNavigate(null);
  useEffect(() => {
    if (Cookies.get("token")) {
      tokenValidate();
      getAllData();
    } else {
      Navigate("/login");
    }
  }, []);

  const tokenValidate = () => {
    axios
      .post(
        `https://9d89-180-241-162-153.ngrok-free.app/admin/token/verify?token=${Cookies.get(
          "token"
        )}`
      )
      .catch((err) => {
        Cookies.set("token", false);
        Navigate("/login");
      });
  };
  const [data, setData] = useState([]);
  const getAllData = () => {
    axios
      .post(
        `https://9d89-180-241-162-153.ngrok-free.app/admin/view?token=${Cookies.get(
          "token"
        )}`
      )
      .then((ress) => {
        setData(ress.data.data);
      })
      .catch((err) => {
        Cookies.set("token", false);
        Navigate("/login");
      });
  };
  const formPelanggan = useRef(null);
  const formKendaraan = useRef(null);
  const formSparePart = useRef(null);
  const formJumlahSparePart = useRef(null);
  const [dataCart, setDataCart] = useState([]);
  let total = 0;
  const handleAddToCart = () => {
    if (
      formJumlahSparePart.current.value.length < 9 &&
      formSparePart.current.value.length < 9
    ) {
      axios
        .post(
          `https://9d89-180-241-162-153.ngrok-free.app/admin/view/parts/${
            formSparePart.current.value
          }?token=${Cookies.get("token")}`
        )
        .then((ress) => {
          setDataCart((prevDataCart) => [
            ...prevDataCart,
            {
              kd_spare_part: formSparePart.current.value,
              spare_parts: ress.data.data[0].spare_parts,
              qty: formJumlahSparePart.current.value,
              harga_satuan: ress.data.data[0].harga,
              total:
                ress.data.data[0].harga *
                parseInt(formJumlahSparePart.current.value),
            },
          ]);
          console.log(dataCart);
        })
        .catch((err) => {
          Cookies.set("token", false);
          Navigate("/login");
        });
    } else {
      alert("data tidak boleh kosong");
      formJumlahSparePart.current.className += " border-danger";
      formSparePart.current.className += " border-danger";
    }
  };
  const formAlamat = useRef(null);
  const formOngkos = useRef(null);
  const handleCekout = () => {
    if (
      formPelanggan.current.value.length < 9 &&
      formKendaraan.current.value.length < 9 &&
      formOngkos.current.value &&
      formAlamat.current.value &&
      dataCart[0]
    ) {
      axios
        .post(
          `https://9d89-180-241-162-153.ngrok-free.app/admin/invoice/create?token=${Cookies.get(
            "token"
          )}`,
          {
            pelanggan: formPelanggan.current.value,
            kendaraan: formKendaraan.current.value,
            alamat: formAlamat.current.value,
            ongkos: parseInt(formOngkos.current.value),
            dataItem: dataCart,
          }
        )
        .then((ress) => {
          alert("Berhasil membaut faktur");
          localStorage.setItem("click", ress.data.data.noFaktur);
          Navigate("/invoice/print");
        })
        .catch((err) => {
          Cookies.set("token", false);
          Navigate("/login");
        });
    } else {
      formPelanggan.current.className += " border-danger";
      formKendaraan.current.className += " border-danger";
      formOngkos.current.className += " border-danger";
      formAlamat.current.className += " border-danger";
    }
  };
  return (
    <>
      <Navbars onwhat="beranda" />
      <section className="py-4">
        <Container>
          <Row className="g-3">
            <Col md="7 d-flex flex-column gap-3">
              <Card className="shadow rounded-3">
                <Card.Body className="p-4">
                  <div className="text-center">
                    <h5 className="fw-bold text-danger d-flex align-items-center gap-2 justify-content-center">
                      INFORMASI PELANGGAN
                      <i class="bx bxs-user-detail fs-3"></i>
                    </h5>
                  </div>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Pelanggan</Form.Label>
                      <Form.Select
                        className="focus-ring focus-ring-danger"
                        ref={formPelanggan}
                      >
                        <option>Pilih Pelanggan</option>
                        {data?.dataPelanggan?.map((item, index) => {
                          return (
                            <option key={index + 1} value={item.kd_pelanggan}>
                              {item.nama_pelanggan}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Kendaraan</Form.Label>
                      <Form.Select
                        className="focus-ring focus-ring-danger"
                        ref={formKendaraan}
                      >
                        <option>Pilih Kendaraan</option>
                        {data?.dataKendaraan?.map((item, index) => {
                          return (
                            <option key={index + 1} value={item.no_polisi}>
                              {item.type} | {item.no_polisi}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Alamat</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Jln. Sudirman, No 3"
                        className="focus-ring focus-ring-danger"
                        ref={formAlamat}
                      />
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
              <Card className="shadow rounded-3">
                <Card.Body className="p-4">
                  <div className="text-center">
                    <h5 className="fw-bold text-danger d-flex align-items-center gap-2 justify-content-center">
                      SPARE PARTS
                      <i class="bx bxs-cog fs-3"></i>
                    </h5>
                  </div>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Sparte Parts</Form.Label>
                      <Form.Select
                        className="focus-ring focus-ring-danger"
                        ref={formSparePart}
                      >
                        <option>Pilih Spare Parts</option>
                        {data?.dataPart?.map((item, index) => {
                          return (
                            <option key={index + 1} value={item.kd_spare_part}>
                              {item.spare_parts}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Jumlah</Form.Label>
                      <Form.Select
                        className="focus-ring focus-ring-danger"
                        ref={formJumlahSparePart}
                      >
                        <option>Pilih Jumlah</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </Form.Select>
                    </Form.Group>
                    <Button
                      className="bg-danger border-danger w-100"
                      onClick={() => handleAddToCart()}
                    >
                      Tambahkan ke keranjang
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col md="5">
              <Card className="shadow rounded-3">
                <Card.Body className="p-4">
                  <div className="d-flex justify-content-between text-danger">
                    <h5 className="fw-bold text-danger d-flex align-items-center gap-2 justify-content-center">
                      KERANJANG
                    </h5>
                    <i class="bx bxs-cart fs-3"></i>
                  </div>

                  <form>
                    <Table striped bordered hover className="mt-2">
                      <thead>
                        <tr>
                          <th>Kode Produk</th>
                          <th>Jumlah</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataCart.map((item, index) => {
                          return (
                            <tr key={index + 1}>
                              <td>{item.kd_spare_part}</td>
                              <td>{item.qty}</td>
                              <td>
                                <FormatRupiah value={item.total} />
                              </td>
                              <td className="d-none">
                                {(total += item.total)}
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan="2" className="fw-bold">
                            Total
                          </td>
                          <td>
                            {" "}
                            <FormatRupiah value={total} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Ongkos</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="50000"
                        className="focus-ring focus-ring-danger"
                        ref={formOngkos}
                      />
                    </Form.Group>
                    <Button
                      className="bg-danger border-danger w-100"
                      onClick={handleCekout}
                    >
                      Checkout
                    </Button>
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Dashboard;
