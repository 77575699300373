import { Container, Navbar, Table } from "react-bootstrap";
import Navbars from "../components/Navbars";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { FormatRupiah } from "@arismun/format-rupiah";

const RiwayatTransaksi = () => {
  const Navigate = useNavigate(null);
  useEffect(() => {
    if (Cookies.get("token")) {
      tokenValidate();
      getData();
    } else {
      Navigate("/login");
    }
  }, []);

  const tokenValidate = () => {
    axios
      .post(
        `https://9d89-180-241-162-153.ngrok-free.app/admin/token/verify?token=${Cookies.get(
          "token"
        )}`
      )
      .catch((err) => {
        Cookies.set("token", false);
        Navigate("/login");
      });
  };
  const [data, setData] = useState([]);
  const getData = () => {
    axios
      .post(
        `https://9d89-180-241-162-153.ngrok-free.app/admin/riwayat-transaksi?token=${Cookies.get(
          "token"
        )}`
      )
      .then((ress) => {
        setData(ress.data.data);
      })
      .catch((err) => {
        Cookies.set("token", false);
        Navigate("/login");
      });
  };

  const handleToPrint = (p) => {
    localStorage.setItem("click", p);
    Navigate("/invoice/print");
  };
  return (
    <>
      <Navbars onwhat="riwayatTransaksi" />
      <section className="py-4">
        <Container>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>No</th>
                <th>No Faktur</th>
                <th>Kode Pelanggan</th>
                <th>No Polisi</th>
                <th>Alamat</th>
                <th>Jumlah</th>
                <th>Tanggal</th>
                <th>Lihat Detail</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{item.no_faktur}</td>
                    <td>{item.kd_pelanggan}</td>
                    <td>{item.no_polisi}</td>
                    <td>{item.alamat}</td>
                    <td>
                      <FormatRupiah value={item.jumlah} />
                    </td>
                    <td>{item.tanggal}</td>
                    <td>
                      <a
                        href="#"
                        className="text-decoration-none"
                        onClick={() => handleToPrint(item.no_faktur)}
                      >
                        Lihat Selengakpnya
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      </section>
    </>
  );
};

export default RiwayatTransaksi;
